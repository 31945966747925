import React from "react";
import PublicLayout from "../../Components/Public/Layout";

class PublicAbout extends React.Component {
  render() {
    return (
      <PublicLayout footer="public" title={this.props.title}>
        <header className="py-5">
          <div className="container px-5">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xxl-6">
                <div className="text-center my-5">
                  <h1 className="fw-bolder mb-3">
                    Our mission is to make technology solutions accessible and
                    efficient for everyone.
                  </h1>
                  <p className="lead fw-normal text-muted mb-4">
                    Luminary Technology was built on the idea that high-quality,
                    functional websites, mobile apps, and FinTech solutions
                    should be available to everyone. Use our open source, free
                    products, or support us by purchasing one of our premium
                    services. We specialize in software development, mobile app
                    creation, web development, and IT consulting to help
                    businesses thrive in the digital age.
                  </p>
                  <a
                    className="btn btn-info btn-lg text-white"
                    href="#scroll-target"
                  >
                    Read our story
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section className="py-5 bg-light" id="scroll-target">
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                <img
                  width="600"
                  height="400"
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src="https://t4.ftcdn.net/jpg/06/95/99/15/240_F_695991561_TkRJURkM2rGGoLyhjBGXXQaC4i2FxI7o.jpg"
                  alt="..."
                ></img>
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Our founding</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Luminary Technology was founded on the principle of innovation
                  and accessibility in the tech industry. Recognizing the need
                  for high-quality, functional, and user-friendly technology
                  solutions, we set out to make web development, mobile app
                  creation, and FinTech services accessible to businesses of all
                  sizes. Our commitment to excellence and customer satisfaction
                  drives us to continuously improve and deliver outstanding
                  products and services. As the founder of Luminary Technology,
                  I am proud to lead a team dedicated to helping businesses
                  thrive in the digital age.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6 order-first order-lg-last">
                <img
                  width="600"
                  height="400"
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src="https://media.istockphoto.com/id/93070445/photo/business-graph.webp?b=1&s=170667a&w=0&k=20&c=O1YI8KOuYldG8MtBe0x0oxtSDTeNCxlFG2-xABa_Vq4="
                  alt="..."
                ></img>
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Growth &amp; beyond</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Expansion in Services: Highlight how Luminary Technology is
                  expanding its services to cater to a broader range of client
                  needs. Market Reach: Discuss how the company is reaching new
                  markets or demographics, showcasing its growth trajectory.
                  Technological Advancements: Emphasize any new technologies or
                  innovations the company is implementing to stay ahead in the
                  industry. Client Success Stories: Share success stories of
                  clients who have grown with Luminary Technology's services.
                  Future Vision: Talk about the company's vision for the future
                  and how it plans to continue its growth trajectory.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 bg-light">
          <div className="container px-5 my-5">
            <div className="text-center">
              <h2 className="fw-bolder">Our team</h2>
              <p className="lead fw-normal text-muted mb-5">
                Dedicated to quality and your success
              </p>
            </div>
            <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
              <div className="col mb-5 mb-5 mb-xl-0">
                <div className="text-center">
                  <img
                    width="150"
                    height="150"
                    className="img-fluid rounded-circle mb-4 px-4"
                    src="https://i.postimg.cc/3w6bRqB8/vipul-passport.png"
                    alt="..."
                  ></img>
                  <h5 className="fw-bolder">Vipul Tiwari</h5>
                  <div className="fst-italic text-muted">Founder &amp; CEO</div>
                </div>
              </div>
              <div className="col mb-5 mb-5 mb-xl-0">
                <div className="text-center">
                  <img
                    width="150"
                    height="150"
                    className="img-fluid rounded-circle mb-4 px-4"
                    src="https://dummyimage.com/150x150/ced4da/6c757d"
                    alt="..."
                  ></img>
                  <h5 className="fw-bolder">Rajesh Bijoriya</h5>
                  <div className="fst-italic text-muted">CFO</div>
                </div>
              </div>
              <div className="col mb-5 mb-5 mb-sm-0">
                <div className="text-center">
                  <img
                    width="150"
                    height="150"
                    className="img-fluid rounded-circle mb-4 px-4"
                    src="https://i.postimg.cc/jjzJww6D/Abhishek-passport.jpg"
                    alt="..."
                  ></img>
                  <h5 className="fw-bolder">Abhishek pachauri</h5>
                  <div className="fst-italic text-muted">
                    Operations Manager
                  </div>
                </div>
              </div>
              <div className="col mb-5">
                <div className="text-center">
                  <img
                    width="150"
                    height="150"
                    className="img-fluid rounded-circle mb-4 px-4"
                    src="https://i.postimg.cc/rmn3SmMk/princepassport.jpg"
                    alt="..."
                  ></img>
                  <h5 className="fw-bolder">Prince Sharma</h5>
                  <div className="fst-italic text-muted">CTO</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </PublicLayout>
    );
  }
}

export default PublicAbout;
