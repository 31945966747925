import React from "react";
import PublicLayout from "../../Components/Public/Layout";

class PublicFaq extends React.Component {
    render() {
        return (
            <PublicLayout footer="public" title={this.props.title}>
                <section className="py-5">
                    <div className="container px-5 my-5">
                        <div className="text-center mb-5">
                            <h1 className="fw-bolder">Frequently Asked Questions</h1>
                            <p className="lead fw-normal text-muted mb-0">How can we help you?</p>
                        </div>
                        <div className="row gx-5">
                            <div className="col-xl-8">
                                <h2 className="fw-bolder mb-3">Account & Billing</h2>
                                <div className="accordion mb-5" id="accordionExample">
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                How do I create an account?
                                            </button>
                                        </h3>
                                        <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                To create an account, click on the "Sign Up" button on the top right corner of the homepage and fill in the required details. You will receive a confirmation email to verify your account.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                How can I update my billing information?
                                            </button>
                                        </h3>
                                        <div className="accordion-collapse collapse" id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                You can update your billing information by going to the "Billing" section in your account settings. Here you can update your payment method and review your billing history.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What payment methods are accepted?
                                            </button>
                                        </h3>
                                        <div className="accordion-collapse collapse" id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                We accept all major credit cards, PayPal, and bank transfers. For more details on payment methods, visit our billing page or contact support.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <h2 className="fw-bolder mb-3">Website Issues</h2>
                                <div className="accordion mb-5 mb-xl-0" id="accordionExample2">
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                I forgot my password, what should I do?
                                            </button>
                                        </h3>
                                        <div className="accordion-collapse collapse show" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
                                            <div className="accordion-body">
                                                Click on the "Forgot Password" link on the login page and enter your registered email address. You will receive an email with instructions to reset your password.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                How can I report a bug or issue with the website?
                                            </button>
                                        </h3>
                                        <div className="accordion-collapse collapse" id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionExample2">
                                            <div className="accordion-body">
                                                To report a bug, please visit our support page and submit a ticket with details of the issue. Our technical team will address it as soon as possible.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                The website is not loading properly, what should I do?
                                            </button>
                                        </h3>
                                        <div className="accordion-collapse collapse" id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#accordionExample2">
                                            <div className="accordion-body">
                                                If the website is not loading properly, try clearing your browser cache and cookies. If the issue persists, contact our support team for further assistance.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="card border-0 bg-light mt-xl-5">
                                    <div className="card-body p-4 py-lg-5">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="text-center">
                                                <div className="h6 fw-bolder">Have more questions?</div>
                                                <p className="text-muted mb-4">
                                                    Contact us at
                                                    <br />
                                                    <a href="mailto:support@luminarytech.com">support@luminarytech.com</a>
                                                </p>
                                                <div className="h6 fw-bolder">Follow us</div>
                                                <a className="fs-5 px-2 link-dark" href="https://twitter.com/luminarytech"><i className="bi-twitter"></i></a>
                                                <a className="fs-5 px-2 link-dark" href="https://facebook.com/luminarytech"><i className="bi-facebook"></i></a>
                                                <a className="fs-5 px-2 link-dark" href="https://linkedin.com/company/luminarytech"><i className="bi-linkedin"></i></a>
                                                <a className="fs-5 px-2 link-dark" href="https://youtube.com/luminarytech"><i className="bi-youtube"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }
}

export default PublicFaq;
