import React from "react";

class PublicHeader extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="bg-dark py-5">
          <div className="container px-5">
            <div className="row gx-5 align-items-center justify-content-center">
              <div className="col-lg-8 col-xl-7 col-xxl-6">
                <div className="my-5 text-center text-xl-start">
                  <h1 className="display-5 fw-bolder text-white mb-2">
                    {" "}
                    Luminary Technology{" "}
                  </h1>
                  <p className="lead fw-normal text-white-50 mb-4">
                    Luminary Technology Transform your digital presence with
                    Luminary Technology. We leverage cutting-edge technologies
                    to quickly design and customize responsive, mobile-first
                    websites, mobile apps, and software solutions, including
                    fintech. Our expertise ensures optimal performance and user
                    experience across all devices, using the world’s most
                    popular open-source toolkits and innovative technologies.
                  </p>
                  <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                    <a
                      className="btn btn-info btn-lg px-4 me-sm-3"
                      href="#features"
                    >
                      Get Started
                    </a>
                    <a className="btn btn-outline-light btn-lg px-4" href="#!">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
                <img
                  className="img-fluid rounded-3 my-5"
                  src="https://images.unsplash.com/photo-1577375729152-4c8b5fcda381?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bGFwdG9wJTIwY29tcHV0ZXJ8ZW58MHx8MHx8fDA%3D"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default PublicHeader;
