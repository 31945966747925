import React from "react";
import PublicLayout from "../../Components/Public/Layout";

class PublicBlog extends React.Component {

    render() {
        return (
            <PublicLayout footer="public" title={this.props.title}>
                
                <section className="py-5">
                    <div className="container px-5">
                        <h1 className="fw-bolder fs-5 mb-4">Luminary Technology Blog</h1>
                        <div className="card border-0 shadow rounded-3 overflow-hidden">
                            <div className="card-body p-0">
                                <div className="row gx-0">
                                    <div className="col-lg-6 col-xl-5 py-lg-5">
                                        <div className="p-4 p-md-5">
                                            <div className="badge bg-primary bg-gradient rounded-pill mb-2">News</div>
                                            <div className="h2 fw-bolder">Latest Update on Our Platform</div>
                                            <p>We are excited to announce the latest updates on our platform. These updates include improved performance, new features, and bug fixes. Our team has worked tirelessly to ensure that our platform remains at the forefront of innovation in the fintech industry. Stay tuned for more updates!</p>
                                            <a className="stretched-link text-decoration-none" href="#!">
                                                Read more
                                                <i className="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-7">
                                        <div className="bg-featured-blog" style={{ "backgroundImage": "url('https://images.unsplash.com/photo-1552664730-d307ca884978?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dGVjaG5vbG9neSUyMGlubm92YXRpb24lMjB0ZWFtJTIwd29ya3xlbnwwfHwwfHx8MA%3D%3D')" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5 bg-light">
                    <div className="container px-5">
                        <div className="row gx-5">
                            <div className="col-xl-8">
                                <h2 className="fw-bolder fs-5 mb-4">News</h2>
                            
                                <div className="mb-4">
                                    <div className="small text-muted">May 12, 2023</div>
                                    <a className="link-dark" href="#!"><h3>Luminary Technology Releases New Feature</h3></a>
                                </div>
                            
                                <div className="mb-5">
                                    <div className="small text-muted">May 5, 2023</div>
                                    <a className="link-dark" href="#!"><h3>New Partnerships Announced</h3></a>
                                </div>
                                
                                <div className="mb-5">
                                    <div className="small text-muted">Apr 21, 2023</div>
                                    <a className="link-dark" href="#!"><h3>How Our Technology is Changing Fintech</h3></a>
                                </div>
                                <div className="text-end mb-5 mb-xl-0">
                                    <a className="text-decoration-none" href="#!">
                                        More news
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="card border-0 h-100">
                                    <div className="card-body p-4">
                                        <div className="d-flex h-100 align-items-center justify-content-center">
                                            <div className="text-center">
                                                <div className="h6 fw-bolder">Contact</div>
                                                <p className="text-muted mb-4">
                                                    For press inquiries, email us at
                                                    <br />
                                                    <a href="mailto:press@luminarytech.com">press@luminarytech.com</a>
                                                </p>
                                                <div className="h6 fw-bolder">Follow us</div>
                                                <a className="fs-5 px-2 link-dark" href="#!"><i className="bi-twitter"></i></a>
                                                <a className="fs-5 px-2 link-dark" href="#!"><i className="bi-facebook"></i></a>
                                                <a className="fs-5 px-2 link-dark" href="#!"><i className="bi-linkedin"></i></a>
                                                <a className="fs-5 px-2 link-dark" href="#!"><i className="bi-youtube"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container px-5">
                        <h2 className="fw-bolder fs-5 mb-4">Featured Stories</h2>
                        <div className="row gx-5">
                            <div className="col-lg-4 mb-5">
                                <div className="card h-100 shadow border-0">
                                    <img className="card-img-top" src="https://plus.unsplash.com/premium_photo-1683141154082-324d296f3c66?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZmludGVjaHxlbnwwfHwwfHx8MA%3D%3D" alt="..." />
                                    <div className="card-body p-4">
                                        <div className="badge bg-primary bg-gradient rounded-pill mb-2">Innovation</div>
                                        <a className="text-decoration-none link-dark stretched-link" href="#!">
                                            <div className="h5 card-title mb-3">How We're Innovating in Fintech</div>
                                        </a>
                                        <p className="card-text mb-0">Learn about the new technologies we're developing to improve financial services.</p>
                                    </div>
                                    <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                        <div className="d-flex align-items-end justify-content-between">
                                            <div className="d-flex align-items-center">
                                            <img
                          width="40"
                          height="40"
                          className="rounded-circle me-3"
                          src="https://images.unsplash.com/photo-1602233158242-3ba0ac4d2167?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Z2lybHxlbnwwfHwwfHx8MA%3D%3D"
                          alt="..."
                        />
                                                <div className="small">
                                                    <div className="fw-bold">Kelly Rowan</div>
                                                    <div className="text-muted">March 12, 2023 · 6 min read</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-5">
                                <div className="card h-100 shadow border-0">
                                    <img className="card-img-top" src="https://imageio.forbes.com/specials-images/imageserve/64b5825a5b9b4d3225e9bd15/0x0.jpg?format=jpg&height=900&width=1600&fit=bounds" alt="..." />
                                    <div className="card-body p-4">
                                        <div className="badge bg-primary bg-gradient rounded-pill mb-2">Media</div>
                                        <a className="text-decoration-none link-dark stretched-link" href="#!">
                                            <div className="h5 card-title mb-3">AI in the News</div>
                                        </a>
                                        <p className="card-text mb-0">Our latest media coverage and industry insights.</p>
                                    </div>
                                    <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                        <div className="d-flex align-items-end justify-content-between">
                                            <div className="d-flex align-items-center">
                                            <img
                          height="40"
                          width="40"
                          className="rounded-circle me-3"
                          src="https://media.istockphoto.com/id/1334828664/photo/indian-architect.webp?b=1&s=170667a&w=0&k=20&c=hQ3SDYlXfBOM9Gsm1B9USuy9jVkgVUxGR5wfHeDm1Cw="
                          alt="..."
                        />
                                                <div className="small">
                                                    <div className="fw-bold"  >Josiah Barclay</div>
                                                    <div className="text-muted">March 23, 2023 · 4 min read</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-5">
                                <div className="card h-100 shadow border-0">
                                    <img className="card-img-top" src="https://images.unsplash.com/photo-1509017174183-0b7e0278f1ec?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnV0dXJlJTIwb2YlMjBmaW50ZWNofGVufDB8fDB8fHww" alt="..." />
                                    <div className="card-body p-4">
                                        <div className="badge bg-primary bg-gradient rounded-pill mb-2">Tech</div>
                                        <a className="text-decoration-none link-dark stretched-link" href="#!">
                                            <div className="h5 card-title mb-3">The Future of Fintech</div>
                                        </a>
                                        <p className="card-text mb-0">Explore our vision for the future of financial technology and how we're leading the charge.</p>
                                    </div>
                                    <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                        <div className="d-flex align-items-end justify-content-between">
                                            <div className="d-flex align-items-center">
                                            <img
                          height="40"
                          width="40"
                          className="rounded-circle me-3"
                          src="https://images.unsplash.com/photo-1560250097-0b93528c311a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cHJvZmVzc2lvbmFsfGVufDB8fDB8fHww"
                          alt="..."
                        />
                                                <div className="small">
                                                    <div className="fw-bold">Evelyn Martinez</div>
                                                    <div className="text-muted">April 2, 2023 · 10 min read</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-end mb-5 mb-xl-0">
                            <a className="text-decoration-none" href="#!">
                                More stories
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </section>
            </PublicLayout>
        );
    }

}

export default PublicBlog;
