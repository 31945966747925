import React from "react";
import PublicLayout from "../../Components/Public/Layout";
import PublicHeader from "../../Components/Public/Header";

class PublicHome extends React.Component {
  render() {
    return (
      <PublicLayout footer="public" title={this.props.title}>
        <PublicHeader />

        <section className="py-5" id="features">
          <div className="container px-5 my-5">
            <div className="row gx-5">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <h2 className="fw-bolder mb-0">
                  Innovate, Build, Succeed with Luminary Technology.
                </h2>
              </div>
              <div className="col-lg-8">
                <div className="row gx-5 row-cols-1 row-cols-md-2">
                  <div className="col mb-5 h-100">
                    <div className="feature bg-info bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-collection"></i>
                    </div>
                    <h2 className="h5"> Customized Mobile Apps</h2>
                    <p className="mb-0">
                      Transform your ideas into intuitive mobile applications.
                      Our team specializes in creating user-friendly,
                      high-performance apps tailored to meet your business
                      needs. Whether you need an app for iOS, Android, or
                      cross-platform, we've got you covered.
                    </p>
                  </div>
                  <div className="col mb-5 h-100">
                    <div className="feature bg-info bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-building"></i>
                    </div>
                    <h2 className="h5"> Software Development</h2>
                    <p className="mb-0">
                      Our custom software solutions are designed to streamline
                      your business processes. We develop software that is
                      robust, scalable, and tailored to your unique
                      requirements, ensuring seamless integration and operation.
                    </p>
                  </div>
                  <div className="col mb-5 mb-md-0 h-100">
                    <div className="feature bg-info bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-toggles2"></i>
                    </div>
                    <h2 className="h5">Responsive Web Design</h2>
                    <p className="mb-0">
                      We craft websites that look stunning and function
                      flawlessly on any device. Our responsive web design
                      ensures that your site provides an optimal viewing
                      experience, whether accessed on a desktop, tablet, or
                      smartphone.
                    </p>
                  </div>
                  <div className="col h-100">
                    <div className="feature bg-info bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-toggles2"></i>
                    </div>
                    <h2 className="h5"> Fintech Solutions</h2>
                    <p className="mb-0">
                      Empower your financial operations with our cutting-edge
                      fintech solutions. From blockchain integration to secure
                      payment systems, we develop technology that makes
                      financial services more efficient and accessible..
                    </p>
                  </div>
                  <div className="col h-100 mt-5">
                    <div className="feature bg-info bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-toggles2"></i>
                    </div>
                    <h2 className="h5"> Cloud Services</h2>
                    <p className="mb-0">
                      Leverage the power of the cloud to enhance your business
                      operations. Our cloud services offer scalable, secure, and
                      cost-effective solutions that enable you to access your
                      data and applications from anywhere, anytime.
                    </p>
                  </div>
                  <div className="col h-100 mt-5">
                    <div className="feature bg-info bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-toggles2"></i>
                    </div>
                    <h2 className="h5"> IT Consulting</h2>
                    <p className="mb-0">
                      Our expert consultants provide strategic advice and
                      practical solutions to help you navigate the complexities
                      of the IT landscape. From digital transformation to IT
                      infrastructure management, we support your journey to
                      success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="py-5 bg-light">
          <div className="container px-5 my-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-10 col-xl-7">
                <div className="text-center">
                  <div className="fs-4 mb-4 fst-italic">
                    "Working with advanced technology templates has saved me
                    tons of development time when building new projects!
                    Starting with a modern, responsive template just makes
                    things easier!"
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <img
                      width="20px"
                      height="20px"
                      className="rounded-circle me-3"
                      src="https://img.freepik.com/premium-photo/photo-document-passport-id-mature-caucasian-man-suit_262388-3596.jpg?w=2000"
                      alt="..."
                    />
                    <div className="fw-bold">
                      Vipul Tiwari
                      <span className="fw-bold text-info mx-1">/</span>
                      CEO, Luminary Technology
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="py-5">
          <div className="container px-5 my-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-8 col-xl-6">
                <div className="text-center">
                  <h2 className="fw-bolder">From our blog</h2>
                  <p className="lead fw-normal text-muted mb-5">
                    At Luminary Technology, we're not just another IT
                    company—we're your strategic partner in harnessing the power
                    of technology to drive business success. In our latest blog
                    post, we explore the transformative potential of artificial
                    intelligence (AI) in revolutionizing customer experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="row gx-5">
              <div className="col-lg-4 mb-5">
                <div className="card h-100 shadow border-0">
                  <img
                    className="card-img-top "
                    src="https://media.istockphoto.com/id/1364859722/photo/artificial-intelligence-concept.webp?b=1&s=170667a&w=0&k=20&c=eHRkMLmlR79tWkAuR0_eJCerG4xNP7iPMgjqE3mzbZU="
                    alt="..."
                  />
                  <div className="card-body p-4">
                    <div className="badge bg-info bg-gradient rounded-pill mb-2">
                      News
                    </div>
                    <a
                      className="text-decoration-none link-dark stretched-link"
                      href="#!"
                    >
                      <h5 className="card-title mb-3">The Future of AI</h5>
                    </a>
                    <p className="card-text mb-0">
                      Artificial Intelligence (AI) has been a game-changer in
                      recent years, and its impact is only expected to grow. As
                      technology evolves, AI is set to revolutionize various
                      industries and our daily lives. Here's a glimpse into the
                      future of AI and how it's poised to shape the world.
                    </p>
                  </div>
                  <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          width="40"
                          height="40"
                          className="rounded-circle me-3"
                          src="https://images.unsplash.com/photo-1602233158242-3ba0ac4d2167?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Z2lybHxlbnwwfHwwfHx8MA%3D%3D"
                          alt="..."
                        />
                        <div className="small">
                          <div className="fw-bold">Kelly Rowan</div>
                          <div className="text-muted">
                            March 12, 2022 &middot; 6 min read
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5">
                <div className="card h-100 shadow border-0">
                  <img
                    className="card-img-top"
                    src="https://media.istockphoto.com/id/1355632594/photo/high-tech-startup-concept-innovative-male-software-engineer-standing-doing-big-data-analysis.webp?b=1&s=170667a&w=0&k=20&c=BfQcYd7crcfcIRhKXRRSXxl1xEqNIeAGyZf1-DTYaiI="
                    alt="..."
                  />
                  <div className="card-body p-4">
                    <div className="badge bg-info bg-gradient rounded-pill mb-2">
                      News
                    </div>
                    <a
                      className="text-decoration-none link-dark stretched-link"
                      href="#!"
                    >
                      <h5 className="card-title mb-3">
                        The Future of Software Development
                      </h5>
                    </a>
                    <p className="card-text mb-0">
                      Software development is an ever-evolving field,
                      continually shaped by emerging technologies and shifting
                      industry needs. As we look ahead, several key trends and
                      innovations are poised to redefine how we build, deploy,
                      and maintain software. Here’s an exploration of the future
                      of software development and what it holds.
                    </p>
                  </div>
                  <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          height="40"
                          width="40"
                          className="rounded-circle me-3"
                          src="https://media.istockphoto.com/id/1334828664/photo/indian-architect.webp?b=1&s=170667a&w=0&k=20&c=hQ3SDYlXfBOM9Gsm1B9USuy9jVkgVUxGR5wfHeDm1Cw="
                          alt="..."
                        />
                        <div className="small">
                          <div className="fw-bold">Josiah Barclay</div>
                          <div className="text-muted">
                            March 23, 2022 &middot; 4 min read
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5">
                <div className="card h-100 shadow border-0">
                  <img
                    className="card-img-top"
                    src="https://media.istockphoto.com/id/1411610324/photo/python-programming-language-computer-source-code-text-example-close-up-on-a-blue-surface.jpg?s=1024x1024&w=is&k=20&c=ovHY6-ZnG8mojW9XdpSitFJJptV0or1xhgsUCeHN3Y0="
                    alt="..."
                  />
                  <div className="card-body p-4">
                    <div className="badge bg-info bg-gradient rounded-pill mb-2">
                      News
                    </div>
                    <a
                      className="text-decoration-none link-dark stretched-link"
                      href="#!"
                    >
                      <h5 className="card-title mb-3">The Future of FinTech</h5>
                    </a>
                    <p className="card-text mb-0">
                      Financial technology, or FinTech, has revolutionized the
                      way we interact with financial services. From mobile
                      banking to blockchain, FinTech innovations are making
                      financial transactions faster, more secure, and
                      accessible. As we look to the future, several key trends
                      and advancements are set to reshape the financial
                      landscape even further
                    </p>
                  </div>
                  <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          height="40"
                          width="40"
                          className="rounded-circle me-3"
                          src="https://images.unsplash.com/photo-1560250097-0b93528c311a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cHJvZmVzc2lvbmFsfGVufDB8fDB8fHww"
                          alt="..."
                        />
                        <div className="small">
                          <div className="fw-bold">Evelyn Martinez</div>
                          <div className="text-muted">
                            April 2, 2022 &middot; 10 min read
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <aside className="bg-secondary bg-gradient rounded-3 p-4 p-sm-5 mt-5">
              <div className="d-flex align-items-center justify-content-between flex-column flex-xl-row text-center text-xl-start">
                <div className="mb-4 mb-xl-0">
                  <div className="fs-3 fw-bold text-white">
                    New products, delivered to you.
                  </div>
                  <div className="text-white-50">
                    Sign up for our newsletter for the latest updates.
                  </div>
                </div>
                <div className="ms-xl-4">
                  <div className="input-group mb-2">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Email address..."
                      aria-label="Email address..."
                      aria-describedby="button-newsletter"
                    />
                    <button
                      className="btn btn-outline-light"
                      id="button-newsletter"
                      type="button"
                    >
                      Sign up
                    </button>
                  </div>
                  <div className="small text-white-50">
                    We care about privacy, and will never share your data.
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </PublicLayout>
    );
  }
}

export default PublicHome;
