import React from "react";
import PublicLayout from "../../Components/Public/Layout";

class PublicPricing extends React.Component {

    render() {
        return (
            <PublicLayout footer="public" title={this.props.title}>
                
                <section className="bg-light py-5">
                    <div className="container px-5 my-5">
                        <div className="text-center mb-5">
                            <h1 className="fw-bolder">Flexible Pricing Plans</h1>
                            <p className="lead fw-normal text-muted mb-0">Choose a plan that fits your needs</p>
                        </div>
                        <div className="row gx-5 justify-content-center">
                        
                            <div className="col-lg-6 col-xl-4">
                                <div className="card mb-5 mb-xl-0">
                                    <div className="card-body p-5">
                                        <div className="small text-uppercase fw-bold text-muted">Starter</div>
                                        <div className="mb-3">
                                            <span className="display-4 fw-bold">$10</span>
                                            <span className="text-muted">/ mo.</span>
                                        </div>
                                        <ul className="list-unstyled mb-4">
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                <strong>2 users</strong>
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                10GB storage
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Unlimited public projects
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Community access
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Basic analytics
                                            </li>
                                            <li className="text-muted">
                                                <i className="bi bi-x"></i>
                                                Priority support
                                            </li>
                                            <li className="text-muted">
                                                <i className="bi bi-x"></i>
                                                Custom integrations
                                            </li>
                                        </ul>
                                        <div className="d-grid"><a className="btn btn-outline-info" href="#!">Choose plan</a></div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="col-lg-6 col-xl-4">
                                <div className="card mb-5 mb-xl-0">
                                    <div className="card-body p-5">
                                        <div className="small text-uppercase fw-bold">
                                            <i className="bi bi-star-fill text-warning"></i>
                                            Professional
                                        </div>
                                        <div className="mb-3">
                                            <span className="display-4 fw-bold">$30</span>
                                            <span className="text-muted">/ mo.</span>
                                        </div>
                                        <ul className="list-unstyled mb-4">
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                <strong>10 users</strong>
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                50GB storage
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Unlimited public projects
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Community access
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Advanced analytics
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Priority support
                                            </li>
                                            <li className="text-muted">
                                                <i className="bi bi-x"></i>
                                                Custom integrations
                                            </li>
                                        </ul>
                                        <div className="d-grid"><a className="btn btn-info text-white" href="#!">Choose plan</a></div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-lg-6 col-xl-4">
                                <div className="card">
                                    <div className="card-body p-5">
                                        <div className="small text-uppercase fw-bold text-muted">Enterprise</div>
                                        <div className="mb-3">
                                            <span className="display-4 fw-bold">$100</span>
                                            <span className="text-muted">/ mo.</span>
                                        </div>
                                        <ul className="list-unstyled mb-4">
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                <strong>Unlimited users</strong>
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                200GB storage
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Unlimited public projects
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Community access
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Comprehensive analytics
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Priority support
                                            </li>
                                            <li className="mb-2">
                                                <i className="bi bi-check text-info"></i>
                                                Custom integrations
                                            </li>
                                            <li className="text-muted">
                                                <i className="bi bi-check text-info"></i>
                                                Dedicated account manager
                                            </li>
                                        </ul>
                                        <div className="d-grid"><a className="btn btn-outline-info" href="#!">Choose plan</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </PublicLayout>
        );
    }
}

export default PublicPricing;
